import React from 'react';
import Layout from '../../components/Layout';
// import blogd1 from '../../assets/images/servicom/1.png';
import { Link } from 'gatsby';
// import blogd2 from '../../assets/images/2022/nurse-ndidi-retire/2.jpg';
// import blogd3 from '../../assets/images/2022/nurse-ndidi-retire/3.jpg';
// import blogd4 from '../../assets/images/2022/nurse-ndidi-retire/4.jpg';
// import View from 'react-view-component/lib/View'

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import { flowRight } from 'lodash';
import { left, right } from '@popperjs/core';

const ClientAccess = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* <img src={blogd2} height={370} /> */}
                  </div>
                  <div className='col-lg-12'>
                    {/* <img src={blogd3} height={370} /> */}
                  </div>
                </div>
                <div>
                  <section className='contact-info-one'>
                    <div className='container'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='contact-info-one__single'>
                            <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div>
                            <h2 className='contact-info-one__title'>
                              CLIENTS’ ACCESS TO LUTH FACILITIES & SERVICES
                            </h2>
                            <p
                              className='contact-info-one__text'
                              style={{
                                marginLeft: 25,
                                marginRight: 25,
                              }}
                            >
                              <strong>Emergencies </strong>
                              <br />
                              Patients may seek emergency Obstetrics and
                              Gynaecological, Medical and Surgical services at
                              the well manned Accidents & Emergency (A&E) Centre
                              located at the main gate of LUTH on a 24 hour
                              basis. Emergency care of children is performed at
                              the Olikoye Ransome-Kuti Children’s Emergency Room
                              (CHER) near the Mushin gate. All emergencies are
                              seen with or without referral. Elective Cases
                              Non-emergency cases (Electives) are seen on
                              referral at the various Out-Patient Clinics
                              located on the first, second and third floors of
                              the A&E Building. Dental Surgery and
                              Maxillo-facial cases are attended to at the School
                              of Dentistry, near the Mushin Gate while
                              Dermatology and Psychiatry out-patient Clinics are
                              held at the LUTH Annex premises on Harvey Road,
                              Yaba Lagos. Information on specific out-patient
                              clinic days are obtainable at information desks on
                              the ground floor of each building. LUTH also runs
                              a model Primary Health Care Centre at Pakoto in
                              Ogun State catering for the primary medical needs
                              of a suburban community in Ifo Local Government
                              area of Ogun State.
                            </p>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='contact-info-one__single'>
                            {/* <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div> */}
                            <div
                              style={{
                                marginLeft: 50,
                                marginRight: 100,
                              }}
                            >
                              <h2 className='contact-info-one__title'>
                                Clinical Departments & Facilities
                              </h2>
                              <table className='servicom-table'>
                                <tr>
                                  <th>Departments/ Schools</th>
                                  <th>Services</th>
                                </tr>
                                <tr>
                                  <td>Medicine</td>
                                  <td>
                                    Cardiology, Neurology, Gastroenterology,
                                    <br />
                                    Haematology, Endocrinology Nephrology,
                                    <br />
                                    Dermatology Respiratory Medicine, Renal
                                    Dialysis
                                  </td>
                                </tr>
                                <tr>
                                  <td>Obstetrics & Gynaecology</td>
                                  <td>
                                    Antenatal, Perinatal cares, Family Planning,{' '}
                                    <br />
                                    Prevention of Maternal to Child Transmission{' '}
                                    <br />
                                    of HIV, Oncology & Pathological Studies,
                                    <br />
                                    Ultrasonography & Foetal Monitoring,
                                    <br />
                                    Reproductive Endocrinology & Fertility
                                    <br />
                                    Regulation, Experimental & Maternal Medicine
                                  </td>
                                </tr>
                                <tr>
                                  <td>Paediatrics</td>
                                  <td>
                                    Gastroenterology, Oncology, Haematology,{' '}
                                    <br />
                                    Respiratory Medicine, Cardiology Neurology,
                                    <br />
                                    Nephrology, Neonatology Nutrition,
                                    <br />
                                    Endocrinology
                                  </td>
                                </tr>
                                <tr>
                                  <td>Surgery</td>
                                  <td>
                                    Orthopaedics & Trauma Care, Urology Burns &
                                    Plastic Surgery,
                                    <br /> Neurosurgery, Paediatric Surgery,
                                    <br /> Ophthalmology, Otorhinolaryngology,
                                    <br />
                                    General Surgery Prosthesis Surgical
                                    Endoscopy
                                  </td>
                                </tr>
                                <tr>
                                  <td>Dental Surgery</td>
                                  <td>
                                    Periodontology
                                    <br /> Prosthetic Surgery
                                    <br />
                                    Maxillofacial Surgery <br />
                                    Orthodontics
                                  </td>
                                </tr>
                                <tr>
                                  <td>Anaesthesia</td>
                                  <td>
                                    Anaesthesia, ICU Care <br />
                                    Pain Management
                                  </td>
                                </tr>
                                <tr>
                                  <td>Community Medicine</td>
                                  <td>
                                    Reproductive healthcare services <br />
                                    Family Planning
                                    <br /> Child welfare clinic <br />
                                    Endemic disease clinic <br />
                                    Intellectual disabilities clinic <br />
                                    Community Outreach Programmes <br />
                                    Public health education and community
                                    mobilisation services
                                  </td>
                                </tr>
                                <tr>
                                  <td>Institute of Child Health</td>
                                  <td>
                                    Training of Community Health Officers,
                                    <br />
                                    Primary Health Care Services at Pakoto
                                  </td>
                                </tr>
                                <tr>
                                  <td>Physiotherapy Staff Clinic</td>
                                  <td>
                                    Physiotherapy, Staff Medical Care <br />
                                    NHIS Services
                                  </td>
                                </tr>
                                <tr>
                                  <td>Radiodiagnosis</td>
                                  <td>X-Ray & Imaging Services</td>
                                </tr>
                                <tr>
                                  <td>Radiotherapy</td>
                                  <td>
                                    Radiotherapy <br /> Oncology
                                  </td>
                                </tr>
                                <tr>
                                  <td>Morbid Anatomy </td>
                                  <td>
                                    Histopathology, Cytology <br /> Mortuary
                                    Services
                                  </td>
                                </tr>
                                <tr>
                                  <td>Laboratories</td>
                                  <td>
                                    Chemical Pathology <br /> Haematology <br />
                                    Blood banking
                                    <br />
                                    Microbiology
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pharmacy</td>
                                  <td>Pharmaceutical Services</td>
                                </tr>
                                <tr>
                                  <td>Nursing Services</td>
                                  <td>Nursing</td>
                                </tr>
                                <tr>
                                  <td>Psychiatry</td>
                                  <td>Psychiatric services</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='contact-info-one__single'>
                            {/* <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div> */}
                            <div
                              style={{
                                marginLeft: 50,
                                marginRight: 100,
                              }}
                            >
                              <h2 className='contact-info-one__title'>
                                Non - Clinical Departments & Facilities
                              </h2>
                              <p>
                                Many support services ensure the smooth delivery
                                of patients’ care. These include the Central
                                Surgical Sterilizing Department (CSSD),
                                Engineering, Medical Records, Laundry,
                                Environmental Sanitation, Stores & Supplies,
                                Orderlies, Maids, Attendants, Security Section,
                                Finance, Catering and Dietetics, Public Health,
                                Social Workers, Corporate Affairs Unit and
                                others. In addition, LUTH runs a number of
                                schools of allied medical disciplines. The
                                School of Nursing and the School of Midwifery
                                have served not only the needs of Lagos and
                                environs but many other states as well. Schools
                                of Post-Basic Nursing in Paediatrics, A&E
                                Nursing, Theatre Nursing, are also well
                                established in LUTH. Other schools include the
                                Schools of Medical Records, Laboratory
                                Technology, Health Information and Management
                                etc. <br />
                                LUTH maintains a 25-bed Guest House for the use
                                of Guest Physicians and Lecturers who frequent
                                the hospital for short exchange of trainers,
                                examinations, conferences and workshops.
                              </p>
                              <h4
                                style={{
                                  marginLeft: 50,
                                  marginRight: 100,
                                  textAlign: left,
                                }}
                              >
                                Wards{' '}
                              </h4>
                              <table
                                className='servicom-table'
                                style={{
                                  marginLeft: 50,
                                  marginRight: 100,
                                  textAlign: left,
                                }}
                              >
                                <tr>
                                  <th>Wards</th>
                                  <th>Cases</th>
                                </tr>
                                <tr>
                                  <td>
                                    A2 <br />
                                    A3
                                    <br /> A4
                                  </td>
                                  <td>
                                    Male Medical
                                    <br />
                                    Female Medical
                                    <br />
                                    Female Medical{' '}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    B1
                                    <br />
                                    B2
                                    <br />
                                    B3
                                    <br />
                                    B4
                                  </td>
                                  <td>
                                    Female Surgical
                                    <br />
                                    Gynaecology
                                    <br />
                                    Male Surgical
                                    <br />
                                    Male Surgical
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    C1
                                    <br />
                                    C2
                                    <br />
                                    C3
                                    <br />
                                    C4
                                  </td>
                                  <td>
                                    Gynaecology
                                    <br />
                                    Postnatal <br />
                                    Postnatal
                                    <br />
                                    Antenatal
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    D1
                                    <br />
                                    D2
                                    <br />
                                    D3
                                  </td>
                                  <td>
                                    Neonatology
                                    <br />
                                    Paediatrics
                                    <br />
                                    Paediatrics
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    E1
                                    <br />
                                    E2
                                    <br />
                                    E3
                                    <br />
                                    E4
                                    <br />
                                    E5
                                    <br />
                                    E6
                                  </td>
                                  <td>
                                    Psychiatry
                                    <br />
                                    Male Surgical <br />
                                    Female Surgical
                                    <br />
                                    Paediatric Surgery
                                    <br />
                                    Male Medical
                                    <br />
                                    Male Surgical
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='contact-info-one__single'>
                            {/* <div className='contact-info-one__icon'>
                              <i className='kipso-icon-health'></i>
                            </div> */}
                            <div
                              style={{
                                marginLeft: 50,
                                marginRight: 100,
                              }}
                            >
                              <h2 className='contact-info-one__title'>
                                SERVICE PROVISION & DELIVERY
                              </h2>
                              <div
                                style={{
                                  textAlign: left,
                                }}
                              >
                                <p>
                                  All the above-stated services shall be offered
                                  to all who seek them within the walls of LUTH.
                                  Unless otherwise directed by the Hospital
                                  Management, no patients must be turned back
                                  without treatment and sympathetic attention by
                                  a senior member of the team to which the
                                  patient is referred. The standard of care
                                  given shall at all times be comparable to the
                                  best available in any tertiary health care
                                  centre in West Africa. All patients have the
                                  right to be served right and reserve the right
                                  to lodge a formal complaint if they do no
                                  receive service as outlined in this Charter.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='col-lg-4'>
                          <div className='contact-info-one__single'>
                            <div className='contact-info-one__icon'>
                              <i className='kipso-icon-email'></i>
                            </div>
                            <h2 className='contact-info-one__title'>
                              For Complaints
                            </h2>
                            <p className='contact-info-one__text'>
                              servicom@luth.gov.ng
                              <br />
                              Servicom Desk Officer: 0704 457 1695
                              <br />
                              Nodal Officer: 0911 656 4504 <br /> &nbsp;
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </section>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div> */}
                {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <ClientAccess />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
